<template>
  <div>
    <h3 class="ui header">
      Types de signalements
    </h3>
    <div
      id="feature_type-list"
      class="ui middle aligned divided list"
    >
      <div
        :class="{ active: loading }"
        class="ui inverted dimmer"
      >
        <div class="ui text loader">
          Récupération des types de signalements en cours...
        </div>
      </div>
      <div
        :class="{ active: importing }"
        class="ui inverted dimmer"
      >
        <div class="ui text loader">
          Traitement du fichier en cours ...
        </div>
      </div>
      <div
        v-for="(type, index) in feature_types"
        :id="type.title"
        :key="type.title + '-' + index"
        class="item"
      >
        <div class="feature-type-container">
          <FeatureTypeLink :feature-type="type" />
          <div class="middle aligned content">
            <div
              v-if="isImporting(type)"
              class="import-message"
            >
              <i
                class="info circle icon"
                aria-hidden="true"
              />
              Import en cours
            </div>
            <template v-else>
              <router-link
                v-if="project && type.is_editable && permissions && permissions.can_create_feature_type && isOnline"
                :to="{
                  name: 'editer-type-signalement',
                  params: { slug_type_signal: type.slug },
                }"
                class="ui compact small icon button button-hover-orange tiny-margin"
                data-tooltip="Éditer le type de signalement"
                data-position="top center"
                data-variation="mini"
                :data-test="`edit-feature-type-for-${type.title}`"
              >
                <i
                  class="inverted grey pencil alternate icon"
                  aria-hidden="true"
                />
              </router-link>

              <router-link
                v-if="project && permissions && permissions.can_create_feature_type && isOnline"
                :to="{
                  name: 'editer-affichage-signalement',
                  params: { slug_type_signal: type.slug },
                }"
                class="ui compact small icon button button-hover-orange tiny-margin"
                data-tooltip="Éditer l'affichage du type de signalement"
                data-position="top center"
                data-variation="mini"
                :data-test="`edit-feature-type-display-for-${type.title}`"
              >
                <i
                  class="inverted grey paint brush alternate icon"
                  aria-hidden="true"
                />
              </router-link>

              <a
                v-if="isProjectAdmin && isOnline"
                class="ui compact small icon button button-hover-red tiny-margin"
                data-tooltip="Supprimer le type de signalement"
                data-position="top center"
                data-variation="mini"
                :data-test="`delete-feature-type-for-${type.title}`"
                @click="toggleDeleteFeatureType(type)"
              >
                <i
                  class="inverted grey trash alternate icon"
                  aria-hidden="true"
                />
              </a>
            </template>

            <router-link
              v-if="project && permissions && permissions.can_create_feature_type && isOnline"
              :to="{
                name: 'dupliquer-type-signalement',
                params: { slug_type_signal: type.slug },
              }"
              class="ui compact small icon button button-hover-green tiny-margin"
              data-tooltip="Dupliquer un type de signalement"
              data-position="top right"
              data-variation="mini"
              :data-test="`duplicate-feature-type-for-${type.title}`"
            >
              <i
                class="inverted grey copy alternate icon"
                aria-hidden="true"
              />
            </router-link>

            <router-link
              v-if="project && permissions && permissions.can_create_feature && !type.geom_type.includes('multi')"
              :to="{
                name: 'ajouter-signalement',
                params: { slug_type_signal: type.slug },
              }"
              class="ui compact small icon button button-hover-green tiny-margin"
              data-tooltip="Ajouter un signalement"
              data-position="top right"
              data-variation="mini"
              :data-test="`add-feature-for-${type.title}`"
            >
              <i
                class="ui plus icon"
                aria-hidden="true"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="feature_types.length === 0">
        <em> Le projet ne contient pas encore de type de signalements. </em>
      </div>
    </div>

    <div id="new-feature-type-container">
      <div
        class="ui small button circular compact floated right icon teal help"
        data-tooltip="Consulter la documentation"
        data-position="bottom right"
        data-variation="mini"
        data-test="read-doc"
      >
        <i
          class="question icon"
          @click="goToDocumentation"
        />
      </div>

      <router-link
        v-if="permissions && permissions.can_update_project && isOnline"
        :to="{
          name: 'ajouter-type-signalement',
          params: { slug },
        }"
        class="ui compact basic button"
        data-test="add-feature-type"
      >
        <i
          class="ui plus icon"
          aria-hidden="true"
        />
        <label class="ui pointer">
          Créer un nouveau type de signalement
        </label>
      </router-link>

      <div
        v-if="permissions && permissions.can_update_project && isOnline"
        class="ui compact basic button button-align-left"
        data-test="add-feature-type-from-geojson"
      >
        <i
          class="ui plus icon"
          aria-hidden="true"
        />
        <label
          class="ui pointer"
          for="geojson_file"
        >
          Créer un nouveau type de signalement à partir d'un GeoJSON
        </label>
        <input
          id="geojson_file"
          type="file"
          accept=".geojson"
          style="display: none"
          name="geojson_file"
          @change="onGeoJSONFileChange"
        >
      </div>

      <div
        v-if="permissions && permissions.can_update_project && isOnline"
        class="ui compact basic button button-align-left"
        data-test="add-feature-type-from-json"
      >
        <i
          class="ui plus icon"
          aria-hidden="true"
        />
        <label
          class="ui pointer"
          for="json_file"
        >
          Créer un nouveau type de signalement à partir d'un JSON (non-géographique)
        </label>
        <input
          id="json_file"
          type="file"
          accept="application/json, .json"
          style="display: none"
          name="json_file"
          @change="onGeoJSONFileChange"
        >
      </div>

      <div
        v-if="permissions && permissions.can_update_project && isOnline"
        class="ui compact basic button button-align-left"
        data-test="add-feature-type-from-csv"
      >
        <i
          class="ui plus icon"
          aria-hidden="true"
        />
        <label
          class="ui pointer"
          for="csv_file"
        >
          Créer un nouveau type de signalement à partir d'un CSV
        </label>
        <input
          id="csv_file"
          type="file"
          accept="application/csv, .csv"
          style="display: none"
          name="csv_file"
          @change="onCSVFileChange"
        >
      </div>

      <router-link
        v-if="IDGO && permissions && permissions.can_update_project && isOnline"
        :to="{
          name: 'catalog-import',
          params: {
            slug,
            feature_type_slug: 'create'
          },
        }"
        class="ui compact basic button button-align-left"
        data-test="add-feature-type-from-catalog"
      >
        <i
          class="ui plus icon"
          aria-hidden="true"
        />
        Créer un nouveau type de signalement à partir du catalogue {{ CATALOG_NAME || 'IDGO' }}
      </router-link>
    </div>

    <div
      v-if="geojsonFileToImport.size > 0"
      id="button-import"
    >
      <button
        :disabled="geojsonFileToImport.size === 0"
        class="ui fluid teal icon button"
        data-test="start-geojson-file-import"
        @click="toNewGeojsonFeatureType"
      >
        <i
          class="upload icon"
          aria-hidden="true"
        /> Lancer l'import avec le fichier
        {{ geojsonFileToImport.name }}
      </button>
    </div>

    <div
      v-if="csvFileToImport.size > 0 && !csvError"
      id="button-import"
    >
      <button
        :disabled="csvFileToImport.size === 0"
        class="ui fluid teal icon button"
        data-test="start-csv-file-import"
        @click="toNewCsvFeatureType"
      >
        <i
          class="upload icon"
          aria-hidden="true"
        /> Lancer l'import avec le fichier
        {{ csvFileToImport.name }}
      </button>
    </div>
    <div
      v-if="csvError"
      class="ui negative message"
    >
      <i
        class="close icon"
        aria-hidden="true"
        @click="csvError = null; csvFileToImport = { name: '', size: 0 }"
      />
      {{ csvError }}
    </div>

    <!-- MODALE FILESIZE -->
    <div
      :class="isFileSizeModalOpen ? 'active' : ''"
      class="ui dimmer inverted"
    >
      <div
        :class="isFileSizeModalOpen ? 'active' : ''"
        class="ui modal tiny"
        style="top: 20%"
      >
        <div class="header">
          Fichier trop grand!
        </div>
        <div class="content">
          <p>
            Impossible de créer un type de signalement à partir d'un fichier
            de plus de 100Mo (celui importé fait {{ geojsonFileSize > 0 ? geojsonFileSize : csvFileSize }} Mo).
          </p>
        </div>
        <div class="actions">
          <div
            class="ui button teal"
            @click="closeFileSizeModal"
          >
            Fermer
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { csv } from 'csvtojson';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import { fileConvertSizeToMo, determineDelimiter } from '@/assets/js/utils';
import FeatureTypeLink from '@/components/FeatureType/FeatureTypeLink';

export default {

  name: 'ProjectFeatureTypes',

  components: {
    FeatureTypeLink
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },

  data() {
    return {
      importing: false,
      slug: this.$route.params.slug,
      isFileSizeModalOpen: false,
      geojsonImport: [],
      csvImport: null,
      csvError: null,
      geojsonFileToImport: { name: '', size: 0 },
      csvFileToImport: { name: '', size: 0 },
      fetchCallCounter: 0,
      hadPending: false
    };
  },

  computed: {
    ...mapState([
      'configuration',
      'isOnline',
      'user_permissions',
    ]),
    ...mapState('feature-type', [
      'feature_types',
      'importFeatureTypeData'
    ]),
    ...mapGetters([
      'permissions'
    ]),

    CATALOG_NAME() {
      return this.configuration.VUE_APP_CATALOG_NAME;
    },
    IDGO() {
      return this.$store.state.configuration.VUE_APP_IDGO;
    },
    isProjectAdmin() {
      return this.user_permissions && this.user_permissions[this.slug] &&
        this.user_permissions[this.slug].is_project_administrator;
    },
    geojsonFileSize() {
      return fileConvertSizeToMo(this.geojsonFileToImport.size);
    },
    csvFileSize() {
      return fileConvertSizeToMo(this.csvFileToImport.size);
    },
  },

  mounted() {
    this.fetchImports();
  },

  methods: {
    ...mapMutations('feature-type', [
      'SET_FILE_TO_IMPORT'
    ]),
    ...mapActions('feature-type', [
      'GET_IMPORTS',
    ]),

    fetchImports() {
      this.fetchCallCounter += 1; // register each time function is programmed to be called in order to avoid redundant calls
      this.GET_IMPORTS({
        project_slug: this.$route.params.slug,
      })
        .then((response) => {
          if (response.data && response.data.some(el => el.status === 'pending')) {
            this.hadPending = true; // store pending import to know if project need to be updated, after mounted
            // if there is still some pending imports re-fetch imports by calling this function again
            setTimeout(() => {
              if (this.fetchCallCounter <= 1 ) {
                // if the function wasn't called more than once in the reload interval, then call it again
                this.fetchImports();
              }                
              this.fetchCallCounter -= 1; // decrease function counter
            }, this.$store.state.configuration.VUE_APP_RELOAD_INTERVAL);
          } else if (this.hadPending) {
            // if no more pending import, get last features
            this.$emit('update');
          }
        });
    },

    isImporting(type) {
      if (this.importFeatureTypeData) {
        const singleImportData = this.importFeatureTypeData.find(
          (el) => el.feature_type_title === type.slug
        );
        return singleImportData && singleImportData.status === 'pending';
      }
      return false;
    },

    goToDocumentation() {
      window.open(this.configuration.VUE_APP_URL_DOCUMENTATION);
    },

    toNewGeojsonFeatureType() {
      this.importing = true;
      if(typeof this.geojsonImport == 'object'){
        if(!Array.isArray(this.geojsonImport)){
          this.$router.push({
            name: 'ajouter-type-signalement',
            params: {
              geojson: this.geojsonImport,
              fileToImport: this.geojsonFileToImport,
            },
          });
        }else{
          this.$router.push({
            name: 'ajouter-type-signalement',
            params: {
              json: this.geojsonImport,
              fileToImport: this.geojsonFileToImport,
            },
          });
        }
      }
      this.importing = false;
    },

    toNewCsvFeatureType() {
      this.importing = true;
      this.$router.push({
        name: 'ajouter-type-signalement',
        params: {
          csv: this.csvImport,
          fileToImport: this.csvFileToImport,

        },
      });
      this.importing = false;
    },

    onGeoJSONFileChange(e) {
      this.importing = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.geojsonFileToImport = files[0];
      // TODO : VALIDATION IF FILE IS JSON
      if (parseFloat(fileConvertSizeToMo(this.geojsonFileToImport.size)) > 100) {
        this.isFileSizeModalOpen = true;
      } else if (this.geojsonFileToImport.size > 0) {
        const fr = new FileReader();
        try {
          fr.onload = (ev) => {
            this.geojsonImport = JSON.parse(ev.target.result);
            this.importing = false;
          };
          fr.readAsText(this.geojsonFileToImport);
          //* stock filename to import features afterward
          this.SET_FILE_TO_IMPORT(this.geojsonFileToImport);
        } catch (err) {
          console.error(err);
          this.importing = false;
        }
      } else {
        this.importing = false;
      }
    },

    onCSVFileChange(e) {
      this.featureTypeImporting = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.csvFileToImport = files[0];
      if (parseFloat(fileConvertSizeToMo(this.csvFileToImport.size)) > 100) {
        this.isFileSizeModalOpen = true;
      } else if (this.csvFileToImport.size > 0) {
        const fr = new FileReader();
        try {
          fr.readAsText(this.csvFileToImport);
          fr.onloadend = () => {
            // Find csv delimiter
            const delimiter = determineDelimiter(fr.result);
            if (!delimiter) {
              this.csvError = `Le fichier ${this.csvFileToImport.name} n'est pas formaté correctement`;
              this.featureTypeImporting = false;
              return;
            }
            this.csvError = null;
            csv({ delimiter })
              .fromString(fr.result)
              .then((jsonObj)=>{
                this.csvImport = jsonObj;
              });
            this.featureTypeImporting = false;
            //* stock filename to import features afterward
            this.SET_FILE_TO_IMPORT(this.csvFileToImport);
          };
        } catch (err) {
          console.error(err);
          this.featureTypeImporting = false;
        }
      } else {
        this.featureTypeImporting = false;
      }
    },

    closeFileSizeModal() {
      this.geojsonFileToImport = { name: '', size: 0 };
      this.csvFileToImport = { name: '', size: 0 };
      this.importing = false;
      this.isFileSizeModalOpen = false;
    },

    toggleDeleteFeatureType(featureType) {
      this.$emit('delete', featureType);
    },
  }

};
</script>

<style>
/* // ! missing style in semantic.min.css */
.ui.right.floated.button {
  float: right;
}
</style>

<style lang="less" scoped>
.feature-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .feature-type-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5em;
  }
  & > .middle.aligned.content {
    display: flex;
  }
}

#new-feature-type-container {
  & > div {
    margin: 1em 0;
  }
  & > div.help {
    margin-top: 0;
  }
  .button:not(.help) {
    line-height: 1.25em;
    .icon {
      height: auto;
    }
  }
}

#button-import {
  margin-top: 0.5em;
}

.button-align-left {
  display: flex;
  align-items: center;
  text-align: left;
  width: fit-content;
}

.import-message {
  color: var(--primary-highlight-color, #008c86);
  white-space: nowrap;
  padding: .25em;
  display: flex;
  align-items: center;
  & > i {
    height: auto;
  }
}
</style>
