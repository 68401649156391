<template>
  <div class="orange card">
    <div class="content">
      <div class="center aligned header">
        Derniers commentaires
      </div>
      <div class="center aligned description">
        <div
          :class="{ active: loading }"
          class="ui inverted dimmer"
        >
          <div class="ui text loader">
            Récupération des commentaires en cours...
          </div>
        </div>
        <div class="ui relaxed list">
          <div
            v-for="(item, index) in last_comments"
            :key="'comment ' + index"
            class="item"
          >
            <div class="content">
              <FeatureFetchOffsetRoute
                :feature-id="item.related_feature.feature_id"
                :properties="{
                  title: item.comment,
                  feature_type: { slug: item.related_feature.feature_type_slug }
                }"
              />
              <div class="description">
                <em>[ {{ item.created_on
                }}<span
                  v-if="user && item.display_author"
                >, par {{ item.display_author }}
                </span>
                  ]</em>
              </div>
            </div>
          </div>
          <em
            v-if="!last_comments || last_comments.length === 0"
          >Aucun commentaire pour le moment.</em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';

export default {
  name: 'ProjectsLastComments',
  
  components: {
    FeatureFetchOffsetRoute,
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState([
      'user'
    ]),
    ...mapState('projects', [
      'last_comments',
    ]),
  },
};
</script>
