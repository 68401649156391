<template>
  <div class="ui grey segment">
    <h3 class="ui header">
      Paramètres du projet
    </h3>
    <div class="ui three stackable cards">
      <div class="card">
        <div class="content">
          <h4 class="ui center aligned icon header">
            <i
              class="disabled grey eye icon"
              aria-hidden="true"
            />
            <div class="content">
              Visibilité des signalements publiés
            </div>
          </h4>
        </div>
        <div class="center aligned extra content">
          {{ project.access_level_pub_feature }}
        </div>
      </div>
      <div class="card">
        <div class="content">
          <h4 class="ui center aligned icon header">
            <i
              class="disabled grey eye icon"
              aria-hidden="true"
            />
            <div class="content">
              Visibilité des signalements archivés
            </div>
          </h4>
        </div>
        <div class="center aligned extra content">
          {{ project.access_level_arch_feature }}
        </div>
      </div>
      <div class="card">
        <div class="content">
          <h4 class="ui center aligned icon header">
            <i
              class="disabled grey cogs icon"
              aria-hidden="true"
            />
            <div class="content">
              Modération
            </div>
          </h4>
        </div>
        <div class="center aligned extra content">
          {{ project.moderation ? "Oui" : "Non" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'ProjectParameters',

  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }

};
</script>
