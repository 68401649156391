<template>
  <div
    v-if="isProjectModalOpen"
    class="ui dimmer modals page transition visible active"
    style="display: flex !important"
  >
    <div
      :class="[
        'ui mini modal',
        { 'transition visible active': projectModalType },
      ]"
    >
      <i
        class="close icon"
        aria-hidden="true"
        @click="CLOSE_PROJECT_MODAL"
      />
      <div class="ui icon header">
        <i
          :class="[projectModalType === 'subscribe' ? 'envelope' : 'trash', 'icon']"
          aria-hidden="true"
        />
        {{
          projectModalType === 'subscribe' ? 'Notifications' : 'Suppression'
        }} du {{
          projectModalType === 'deleteFeatureType' ? 'type de signalement ' + featureTypeToDelete.title : 'projet'
        }}
      </div>
      <div class="content">
        <div v-if="projectModalType !== 'subscribe'">
          <p class="centered-text">
            Confirmez vous la suppression du {{
              projectModalType === 'deleteProject' ?
                'projet, ainsi que les types de signalements' :
                'type de signalement'
            }} et tous les signalements associés&nbsp;?
          </p>
          <p class="centered-text alert">
            Attention cette action est irreversible !
          </p>
        </div>
        <button
          id="validate-modal"
          :class="['ui compact fluid button', projectModalType === 'subscribe' && !isSubscriber ? 'green' : 'red']"
          @click="handleModalAction"
        >
          <span v-if="projectModalType === 'subscribe'">
            {{
              isSubscriber
                ? "Se désabonner de ce projet"
                : "S'abonner à ce projet"
            }}
          </span>
          <span v-else>
            Supprimer le
            {{
              projectModalType === 'deleteProject'
                ? 'projet'
                : 'type de signalement'
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {

  name: 'ProjectModal',

  props: {
    isSubscriber: {
      type: Boolean,
      default: false
    },
    featureTypeToDelete: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    ...mapState('modals', [
      'isProjectModalOpen',
      'projectModalType'
    ])
  },

  methods: {
    ...mapMutations('modals', [
      'CLOSE_PROJECT_MODAL'
    ]),

    handleModalAction() {
      this.$emit('action', this.projectModalType);
    }
  }

};
</script>

<style scoped>
.alert {
  color: red;
}
.centered-text {
  text-align: center;
}
</style>
