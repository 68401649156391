<template>
  <div class="red card">
    <div class="content">
      <div class="center aligned header">
        Derniers signalements
      </div>
      <div class="center aligned description">
        <div
          :class="{ active: loading }"
          class="ui inverted dimmer"
        >
          <div class="ui text loader">
            Récupération des signalements en cours...
          </div>
        </div>
        <div class="ui relaxed list">
          <div
            v-for="(item, index) in features.slice(0,5)"
            :key="item.properties.title + index"
            class="item"
          >
            <div class="content">
              <div>
                <FeatureFetchOffsetRoute
                  :feature-id="item.id"
                  :properties="item.properties"
                />
              </div>
              <div class="description">
                <em>
                  [{{ item.properties.created_on }}
                  <span v-if="user && item.properties.creator">
                    , par
                    {{
                      item.properties.creator.full_name
                        ? item.properties.creator.full_name
                        : item.properties.creator.username
                    }}
                  </span>
                  ]
                </em>
              </div>
            </div>
          </div>
          <em
            v-if="features.length === 0 && !loading"
          >Aucun signalement pour le moment.</em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';


export default {

  name: 'ProjectLastFeatures',

  components: {
    FeatureFetchOffsetRoute,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState('feature', [
      'features'
    ]),
    ...mapState([
      'user'
    ]),
  },

  mounted() {
    this.fetchLastFeatures();
  },

  methods: {
    fetchLastFeatures() {
      this.loading = true;
      this.$store.dispatch('feature/GET_PROJECT_FEATURES', {
        project_slug: this.$route.params.slug,
        ordering: '-created_on',
        limit: 5,
      })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  }
};
</script>
